@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$third_color: lightgray;
$dark_color: rgb(31, 34, 40);
$fb_color: #3b5998;
$tw_color: #55acee;
$li_color: #86888a;
$ta_color: #589442;

body {
    background-color: #999999;
    color: #666666;
    font-family: Open Sans,sans-serif;
}


a {
    color: $third_color;
    outline: 0 !important;
}

a:active, a:hover, a:focus {
    outline: 0 !important;
    text-decoration: none;
}

a:hover {
    color: $third_color;
    text-decoration: underline;
}

a:focus, a:active {
    color: $third_color;
}

.navbar-fixed-top {
    .navbar-right {
        margin-right: 0px;
    }
}

.navbar-header {
    min-height: 70px;
}

.navbar-nav li a {
    line-height: 40px;
    color: white;
    font-weight: 700;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .15em;
    font-family: brandon-grotesque,sans-serif;
    font-weight: 500;
}

.navbar-nav li a:active, .navbar-nav li a:focus, .navbar-nav li a:visited {
    color: white !important;
}

.navbar-nav li a:hover, .navbar-nav li a.active {
    color: $third_color !important;
}

.navbar-toggle {
    border: none !important;
    margin-top: 13px;
    margin-bottom: 0px;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 50px;
    height: 3px;
    border-radius: 1px;
    margin-bottom: 8px;

    &:last-child() {
        margin-bottom: 0px;
    }
}

.bg_white {
    background-color: white;
}

.bg_gray {
    background-color: #f2f2f2;
}



.margin_top_bottom_60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.social {
    position: relative;
    z-index: 2;
}

.footer {
    background-image: url('../img/stiphout_kleurfooter.jpg');
    height: 10px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
}

.modal-content {
    border-radius: 0px;
}

.modal-header {
    border-bottom: 0px;
}

@media (max-width: 767px) { 
    
}

@media (max-width: 600px) { 
    
}

@media (max-width: 500px) { 
  
}

@media (max-width: 400px) { 
   
}


@media (max-width: 321px) { 
    
}







.color-dark {
    color: $dark_color !important;
}

.bg-color-dark {
    background-color: $dark_color !important;
}


.navbar-default {
    background-color: rgba(25, 27, 32, 0.9);
    background-color: #1f2228;
    margin-bottom: 0px;
    border-radius: 0px;
    border-width: 0px;
}

.full-page {
    height: 100vh;
    background-color:rgba(31, 34, 40, 0.8);
    position: relative;
}

.center_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.small-logo {
    max-width: 140px;
    margin-top: 8px;
    margin-left: 8px;
}

video#bgvid { 
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.center_bottom {
    position: absolute;
    bottom: 0px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.down_from_home {
    text-align: center;
    font-size: 60px;
    cursor: pointer;
}

.down_from_home i:hover {
    color: gray;
    /* */
}

h3 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .15em;
    font-family: brandon-grotesque,sans-serif;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
}


/*.navbar1 {
    background-color: rgba(25, 27, 32, 0.9);
    opacity:0;
    overflow: hidden;
    -webkit-transition:all 0.2s linear;
     -moz-transition:all 0.2s linear;  
     -o-transition:all 0.2s linear;         
     transition:all 0.2s linear;  
}
*/

.show-me {
    opacity:1;
    /*height: 150px;
    padding: 8px;  */ 
}

.hide-me {
    display: none;
}

.menu {
    padding-left: 25px;
}

.navbar-default .navbar-nav > li > a {
    color: white;
}

.navbar {
    border-width: 0px;
}

.welcome {
    font-size: 18px;
    color: lightgray;
    font-family: brandon-grotesque,sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 22px;
}

.padding-top-64 {
    padding-top: 64px;
}

.bg-screens {
    background-image: url('../img/bg_screens.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /*height: 100%;
    width: 100%;*/
    /*padding-left: 0px;
    padding-right: 0px;*/
}

.bg-black {
    background-color:rgba(31, 34, 40, 0.6);
}

.bg-black-semi {
    background-color:rgba(31, 34, 40, 0.96);
}

.bg-black-full {
    background-color:rgba(31, 34, 40, 1);
}

#owl-bg {
    opacity: 0.1 !important;
}

#owl-bg .item{
    margin-left: 1px;
   /* border: 1px solid black;
    border-color: rgb(31, 34, 40);*/
}
.customNavigation{
  text-align: center;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.iphone-6-frame {
    background-image: url('../img/phone.png');
    background-repeat: no-repeat;
    width: 340px;
    height: 681px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 75px;
    position: absolute;
    z-index: 10;
    top: -75px;
}

#owl-bg {
    margin-top: 75px;
}

.iphone-wrapper-outer {
    margin-top: 100px;
    margin-bottom: 50px;
}

.iphone-wrapper {
    position: relative;
}


.header-1 {
    font-size: 36px;    
    font-weight: 300;
    color: #F3F3F3;
    margin-bottom: 20px;
}

.header-1-on-white {
    color: #1F2228;
}

.sub-header-1 {
    font-size: 18px;
    line-height: 29px;
    color: #6d737f;
}

.sub-head-1-on-white {
    color: #595959;
}

.padding-bottom-40 {
    padding-bottom: 60px;
}

.margin-top-bottom-wrap {
    margin-top: 100px;
    margin-bottom: 100px;
}

.center {
    text-align: center;
}

.nav-arrows {
    position: absolute;
    font-size: 90px;
    top: 30%;
    z-index: 9999;
    opacity: 0.8;
    color: lightgray;
    cursor: pointer;
}


.nav-arrows:hover {
    color: white;
}

.next-item {
    padding-right: 20px;
    right: 0;
}

.prev-item {
    left: 0;
    padding-left: 20px;
}

.default-content-white {
    font-size: 16px;
    color: #F3F3F3;
    text-align: center;
}

.contact-icon {
    font-size: 40px;
    margin-bottom: 20px;
}

.about-icon {
    margin-bottom: 30px;
    font-size: 70px;
}

.logo-footer {
    margin: 0 auto;
    max-width: 200px;
    margin-top: 40px;
}

a.fb-icon:hover {
    background-color: $fb_color !important;
}

a.tw-icon:hover {
    background-color: $tw_color !important;
}

a.li-icon:hover {
    background-color: $li_color !important;
}

a.fb-icon:hover i, a.tw-icon:hover i, a.li-icon:hover i {
    color: white !important;
}

.gray-scale-customer {
    margin-bottom: 20px;    
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.gray-scale-customer:hover {
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
}


.bin-wrap {
    position: relative;
    color: #B0B1B4;
    font-family: 'Courier';
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    font-size: 9px;
    margin: 0 auto;
    cursor: crosshair;
}

.inner-bin-wrap {
    position: absolute;
    width: 107px;
    left: 55px;
    top: 48px;
    z-index: 2;
}


.num {
    clear: both;
    position: relative;
    top: -3px;
    z-index: 1;
}


.link-button {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 35px;
    transition: all ease .4s;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border-radius: 0px;
}

.link-button-dark {
    color:  rgb(31, 34, 40);
    border: 2px solid rgb(31, 34, 40);

    &:hover {
        background-color: rgb(31, 34, 40);
        color: white;
    }
}

.link-button-light {
    color:  $third_color;
    border: 2px solid $third_color;

    &:hover {
        background-color: $third_color;
        color: rgb(31, 34, 40);
    }
}



.contact-button {
    margin-bottom: 100px;
    margin-top: 50px;
}

.item-info {
    text-align: center;
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(216, 216, 216, 0.8);
    padding-bottom: 20px;
    display: none;


    .item-to-bottom {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;        
        margin-left: auto;
        margin-right: auto; 
    }

    .item-info-bttn {        
        width: 60%;       
    }

    .item-info-bttn:hover, .item-info-bttn:active, .item-info-bttn:focus, .item-info-bttn:visited {
        text-decoration: none;        
    }

    .item-info-social {
        margin-bottom: 20px;

        a {
            font-size: 40px;
            color: $dark_color;
            

            .fa {
                transition: all ease .1s;
                margin-left: 4px;
                margin-right: 4px;
            }

            .fa-facebook-square:hover {
                color: $fb_color;
            }

            .fa-twitter-square:hover {
                color: $tw_color;
            }

            .fa-linkedin-square:hover {
                color: $li_color;
            }

            .fa-tripadvisor:hover {
                color: $ta_color;
            }
        }
    }

}

.item-info img {
    margin: 0 auto;
}

.item-main-img.blurred {
    transition: all ease .1s;
     -webkit-filter: blur(5px); 
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.modal-content {
    background-color: #f2f2f2;
    text-align: center;
}

.modal-content input {
    height: 50px;
    background-color: #f2f2f2;
    color: $dark_color;
    border-radius: 0px;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
        margin-top: 100px;
    }
}

.go-ahead-wrap {
    position: absolute;
    z-index: 100;
    right: -120px;
    top: -20px;

    img {
        width: 115px;
    }

}

.modal-body .error {
    border-color: red;
}   

.tel_answer {
    display: none;
}

@media (max-width: 581px) { 
    .projecs_wrapper {
        display: none;
    }
}

